const links = document.querySelectorAll('.nav-link');

links.forEach(el => el.addEventListener('click', event => {
  document.getElementById('navbarContent').classList.remove("show");
}));

document.getElementById('openMenu').onclick = function () {
  document.getElementById('navbarContent').classList.add("show");
}

document.getElementById('closeMenu').onclick = function () {
  document.getElementById('navbarContent').classList.remove("show");
}

window.addEventListener('scroll', () => {
  toggleHeader();
});

function toggleHeader() {
  const header = document.getElementById('header');
  let yPosition = window.pageYOffset;

  if (yPosition > 50) {
    header.classList.add('nav-fixed');
  } else {
    header.classList.remove('nav-fixed');
  }
}

document.getElementById('formContato').addEventListener("submit", function(event) {
  event.preventDefault();
  enviarMsg()
}, true);

function enviarMsg() {
  const nome = document.getElementById('nome').value;
  const email = document.getElementById('email').value;
  const cidade = document.getElementById('cidade').value;
  const msg = document.getElementById('mensagem').value;

  window.open(`https://wa.me/5535991703333?text=Nome%3A%20${nome}%0AEmail%3A%20${email}%0ACidade%3A%20${cidade}%0AMensagem%3A%20${msg}`, '_blank');
}

toggleHeader();
